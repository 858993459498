#agent {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#agent td,
#agent th {
  border: 1px solid #ddd;
  padding: 8px;
}

#agent th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  white-space: nowrap;
}

#agent th:nth-child(1) {
  width: 6%;
}

#agent th:nth-child(3) {
  width: 15%;
}

#agent th:nth-child(4) {
  width: 30%;
}

#agent tr:hover {
  background-color: #ddd;
}

#agent tr:nth-child(even) {
  background-color: #f2f2f2;
}

#agent td:nth-child(1) {
  text-align: right;
  width: 80px;
}

#agent td:nth-child(1) a {
  text-decoration: none;
}

#agent td:nth-child(1) a:hover {
  text-decoration: none;
}

#agent td:nth-child(2) {
  text-align: center;
  width: auto;
}

#agent td:nth-child(3),
#agent td:nth-child(4),
#agent td:nth-child(5),
#agent td:nth-child(6) {
  width: auto;
  text-align: center;
}

#agent td:nth-child(7) {
  text-align: center;
  width: 100px;
}

#agent td:nth-child(8) {
  text-align: center;
  width: 125px;
}

#agent td:nth-child(9) {
  text-align: left;
  width: 125px;
}

.orderinfo {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.orderinfotr {
  background-color: "white";
}
